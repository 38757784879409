import "bootstrap/dist/css/bootstrap.min.css";

import { Footer } from "../components/Footer";
import { Col, Container, Row } from "react-bootstrap";
import headerImg from "../assets/img/bannerboy.png";

export default function About() {
  return (
    <section className="titletext">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <h1>About Me</h1>
            <p>Hi I am Sharvil an Information Technology Engineer</p>
            <span className="tagline">Hobbies And Intrests</span>
            <p>
              Creativity has fascinated me ever since I was a child. I loved
              taking things apart to figure out how they worked and building
              something new, even if it meant accidentally destroying them in
              the process.<br></br>In terms of sports, I enjoy watching Formula
              1, football, and cricket to some extent.<br></br>My favorite team
              in Formula 1 is the Mercedes-AMG team, and Lewis Hamilton is my
              favorite driver. I'm always ready to geek out if you want to talk
              about anything related to F1.<br></br>I support Tottenham Hotspur
              F.C. in the Premier League and FC Barcelona in La Liga when it
              comes to football. The GOAT Lionel Messi is my footballer of all
              time.<br></br>As far as cricket is concerned, I'm a fan of Team
              India and the Mumbai Indians.<br></br>Despite not being
              particularly skilled at any of them, I occasionally play sports
              with my friends.<br></br>Besides sports, I have a passion for
              music and love singing with friends and family whenever I get the
              chance. Painting is also an interest of mine, although I rarely
              get the opportunity to indulge in it.
            </p>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img className="imgaboutus" src={headerImg} alt="Header Img"></img>
          </Col>
        </Row>
      </Container>
      <Container>
        <Footer />
      </Container>
    </section>
  );
}
