import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import React from "react";
import emailjs from "emailjs-com";

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phoneno: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // State for submission status
  const [submitMessage, setSubmitMessage] = useState(""); // State for success/error message

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const sendEmail = (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Set submitting state for UI feedback
    setSubmitMessage(""); // Clear previous message

    emailjs
      .sendForm(
        "service_elyzptr", // Replace with your service ID
        "template_6mga1lx", // Replace with your template ID
        event.target, // Reference the form element
        "-8zhXDThzY0tEz8UL" // Replace with your public key (optional)
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setFormData({ name: "", email: "", phoneno: "", message: "" }); // Clear form data
        setSubmitMessage("Your message has been sent successfully!"); // Success message
        setIsSubmitting(false); // Reset submitting state
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setSubmitMessage(
          "There was an error sending your message. Please try again later."
        ); // Error message
        setIsSubmitting(false); // Reset submitting state
      });
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <img src={contactImg} alt="Contact Us" />
          </Col>
          <Col size={12} md={6}>
            <h2>Get In Touch</h2>
            <p>
              Feel free to reach out to me. I'm always open to discuss new
              projects.
            </p>
            <form onSubmit={sendEmail}>
              <Row>
                <Col size={12} sm={6} className="px-1 col-sm-12">
                  <label>Full Name*</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col size={12} sm={6} className="px-1">
                  <label>Email*</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email ID"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col size={12} sm={6} className="px-1">
                  <label>Phone No*</label>
                  <input
                    type="tel"
                    name="phoneno"
                    id="phoneno"
                    placeholder="Enter Phone No"
                    value={formData.phoneno}
                    onChange={handleChange}
                    required
                  />
                </Col>
                {/*<Col size={12} sm={6} className="px-1">
                  <input
                    type="tel"
                    value={formDetails.phone}
                    placeholder="Phone No."
                    onChange={(e) => onFormUpdate("phone", e.target.value)}
                  />
                </Col> */}
                <Col size={12} className="px-1">
                  <label>Message*</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Enter Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <button type="submit">Send</button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
