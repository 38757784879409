import { Container, Row, Col } from "react-bootstrap";
import ontime from "../assets/img/project-img1.png";
import healthcare from "../assets/img/healthcare.png";
import tindog from "../assets/img/tindog.png";
import productportfolio from "../assets/img/Product-portfolio.png";

export const PortfolioSection = () => {
  return (
    <section className="best" id="product">
      <h2>Portfolio</h2>
      <p>Some of the projects i have worked on.</p>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={12} xl={3}>
            <div className="tagline">
              <img src={healthcare} alt="healthcare"></img>
              <br></br>

              <div className="port-div2">
                <ul class="tags">
                  <li>
                    <a class="tag">React</a>
                  </li>
                  <li>
                    <a class="tag">JS</a>
                  </li>
                  <li>
                    <a class="tag">HTML</a>
                  </li>
                  <li>
                    <a class="tag">CSS</a>
                  </li>
                </ul>
                <span className="best-span">Healthcare</span>
              </div>
              <div className="port-div3">
                <a
                  className="button-portfolio"
                  href="https://healthcare-sharvs.netlify.app/"
                  target="_blank"
                >
                  View Page
                </a>
              </div>
            </div>
          </Col>

          <Col xs={12} md={12} xl={3}>
            <div className="tagline">
              <img src={productportfolio} alt="Product Portfolio"></img>
              <br></br>

              <div className="port-div2">
                <ul class="tags">
                  <li>
                    <a class="tag">React</a>
                  </li>
                  <li>
                    <a class="tag">JS</a>
                  </li>
                  <li>
                    <a class="tag">HTML</a>
                  </li>
                  <li>
                    <a class="tag">CSS</a>
                  </li>
                </ul>
                <span className="best-span">Product Portfolio</span>
              </div>
              <div className="port-div3">
                <a
                  className="button-portfolio"
                  href="https://product-sharvs.netlify.app/"
                  target="_blank"
                >
                  View Page
                </a>
              </div>
            </div>
          </Col>

          <Col xs={12} md={12} xl={3}>
            <div className="tagline">
              <img src={tindog} alt="tindog"></img>
              <br></br>

              <div className="port-div2">
                <ul class="tags">
                  <li>
                    <a class="tag">HTML</a>
                  </li>
                  <li>
                    <a class="tag">CSS</a>
                  </li>
                </ul>
                <span className="best-span">Tin Dog</span>
              </div>
              <div className="port-div3">
                <a
                  className="button-portfolio"
                  href="https://tindog-sharvs.netlify.app/"
                  target="_blank"
                >
                  View Page
                </a>
              </div>
            </div>
          </Col>

          <Col xs={12} md={12} xl={3}>
            <div className="tagline">
              {/* <img src={ontime} alt="ontime"></img>
              <br></br> */}
              <span className="best-span">Adding More Soon...</span>
              <br></br>
              {/* <a className="button-portfolio" href="#" target="_blank">
                View Page
              </a> */}
            </div>
          </Col>

          {/* <Col xs={6} md={6} xl={3}>
            <div className="tagline">
              <img src={ontime} alt="ontime"></img>
              <br></br>
              <span>Title</span>
              <br></br>
              <a className="button-portfolio" href="#" target="_blank">
                View Page
              </a>
            </div>
          </Col>
          <Col xs={6} md={6} xl={3}>
            <div className="tagline">
              <img src={ontime} alt="ontime"></img>
              <br></br>
              <span>Title</span>
              <br></br>
              <a className="button-portfolio" href="#" target="_blank">
                View Page
              </a>
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};
