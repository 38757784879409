import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/bannerboy.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContactUs from "../pages/ContactUs";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(200 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Web Developer", "Web Designer"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">Welcome to my Portfolio</span>
            <h1>
              {`Hi!`}
              <br></br>
              {`I'm Sharvil`}
              <br></br>{" "}
              <span
                className="txt-rotate"
                dataPeriod="1000"
                data-rotate='[ "Web Developer", "Web Designer"]'
              >
                <span className="wrap">{text}</span>
              </span>
            </h1>
            <p>
              I take pride in my ability to transform ideas into stunning and
              user-friendly websites. My expertise in web design and development
              allows me to craft websites that not only look fantastic but also
              function flawlessly.
            </p>
            <HashLink to="/contact">
              <div>
                <button>
                  Let’s Connect <ArrowRightCircle size={25} />
                </button>
              </div>
            </HashLink>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Header Img"></img>
          </Col>
        </Row>
      </Container>
      <Routes>
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </section>
  );
};
