import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/img/Logov1.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter, Route, Routes, NavLink } from "react-router-dom";
import { Banner } from "./Banner";
import { Skills } from "./Skills";
import { Projects } from "./Projects";
import { Contact } from "./Contact";
import { Footer } from "./Footer";
import { PortfolioSection } from "../components/PortfolioSection";
// import { Cta } from "../components/Cta";
import About from "../pages/About";
import ContactUs from "../pages/ContactUs";
import Portfolio from "../pages/Portfolio";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  const Home = () => {
    return (
      <div className="App">
        <Banner />
        {/* <Cta /> */}
        <Skills />
        <PortfolioSection />
        <Contact />
        <Footer />
      </div>
    );
  };

  return (
    <BrowserRouter>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img className="logonavbar" src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                as={NavLink}
                to="/"
                className="navbar-link"
                onClick={() => {
                  // Code to close the dropdown menu (implementation depends on Bootstrap version)
                  document
                    .getElementById("basic-navbar-nav")
                    .classList.remove("show");
                }}
              >
                Home
              </Nav.Link>
              {/* <Nav.Link
                href="#skills"
                className={
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("skills")}
              >
                Skills
              </Nav.Link>
              <Nav.Link
                href="#portfolio"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("portfolio")}
              >
                Portfolio
              </Nav.Link> */}
              <Nav.Link
                as={NavLink}
                to="/portfolio"
                className="navbar-link"
                onClick={() => {
                  // Code to close the dropdown menu (implementation depends on Bootstrap version)
                  document
                    .getElementById("basic-navbar-nav")
                    .classList.remove("show");
                }}
              >
                Portfolio
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/about"
                className="navbar-link"
                onClick={() => {
                  // Code to close the dropdown menu (implementation depends on Bootstrap version)
                  document
                    .getElementById("basic-navbar-nav")
                    .classList.remove("show");
                }}
              >
                About Me
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a
                  href="https://www.linkedin.com/in/sharvil-prasad-bbbb81152"
                  target="_blank"
                >
                  <img src={navIcon1} alt="" />
                </a>
                <a href="https://twitter.com/sharvs_" target="_blank">
                  <img src={navIcon2} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/sharvilprasad/"
                  target="_blank"
                >
                  <img src={navIcon3} alt="" />
                </a>
              </div>
              <HashLink to="/contact">
                <button
                  className="vvd"
                  onClick={() => {
                    // Code to close the dropdown menu (implementation depends on Bootstrap version)
                    document
                      .getElementById("basic-navbar-nav")
                      .classList.remove("show");
                  }}
                >
                  <span>Let’s Connect</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
      </Routes>
    </BrowserRouter>
  );
};
