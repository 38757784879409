import "bootstrap/dist/css/bootstrap.min.css";

import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";
import { Container } from "react-bootstrap";
import React from "react";

export default function ContactUs() {
  return (
    <section className="">
      <div className="titletext">
        <h1>Let's Connect</h1>
      </div>
      <Container>
        <Contact />
        <Footer />
      </Container>
    </section>
  );
}
