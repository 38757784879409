import { Col, Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import htmlimg from "../assets/img/html-5.png";
import cssimg from "../assets/img/css-3.png";
import jsimg from "../assets/img/js.png";
import reactimg from "../assets/img/react.png";
import wordpress from "../assets/img/wordpress.png";
import shopify from "../assets/img/shopify.png";
import wix from "../assets/img/wix.png";
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import { useEffect, useRef } from "react";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselRef = useRef(null);
  const autoScroll = () => {
    // Access the carousel instance using the ref
    const carousel = carouselRef.current;
    if (carousel) {
      // Logic for auto scroll
      carousel.next(); // This moves to the next item
    }
  };
  useEffect(() => {
    const intervalId = setInterval(autoScroll, 2000); // Auto scroll every 2 seconds
    return () => clearInterval(intervalId); // Cleanup function to stop auto scroll on unmount
  }, []);

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p></p>
              <Carousel
                ref={carouselRef}
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={htmlimg} alt="Image" />
                </div>
                <div className="item">
                  <img src={cssimg} alt="Image" />
                </div>
                <div className="item">
                  <img src={jsimg} alt="Image" />
                </div>
                <div className="item">
                  <img src={reactimg} alt="Image" />
                </div>
                <div className="item">
                  <img src={wordpress} alt="Image" />
                </div>
                <div className="item">
                  <img src={shopify} alt="Image" />
                </div>
                <div className="item">
                  <img src={wix} alt="Image" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
